<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto pb-5">

          <div class="px-4 mb-5">
            <p class="titulo-pag">Plano de ação</p>
            <h1 v-if="this.$route.name === 'PlanosDeAcao'" class="mt-3">Planos vigentes</h1>
            <h1 v-if="this.$route.name === 'PlanosDeAcaoHistorico'" class="mt-3">Histórico de planos</h1>
            <span v-if="this.$route.name === 'PlanosDeAcao'">Aqui você gerencia e visualiza os planos vigentes</span>
            <span v-if="this.$route.name === 'PlanosDeAcaoHistorico'">Aqui você gerencia e visualiza os históricos de plano de ação</span>
          </div>
           <div class="pt-3 px-4 pb-5 bg-white rounded-3">
            <label for="searchInput" class="form-label fw-bold">Busca</label>
            <div class="d-flex justify-content-between">
              <input type="text" class="form-control busca-lista" id="searchInput" name="searchInput" v-model="search" placeholder="Digite o nome ou código que quer encontrar">
              <router-link class="btn btn-dark-blue px-4 py-2 rounded-pill" :to="{ name: 'PlanosDeAcao.Cadastro'}">Criar novo plano de ação</router-link>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3 mb-4">
            <select class="form-select w-auto mr-2" aria-label="Filtra por período" v-model="searchPeriodo" style="margin-right: 14px;">
              <option selected value="">Período</option>
              <option value="mes">Último mês</option>
              <option value="semana">Últimos 7 dias</option>
            </select>
            <select class="form-select w-auto" aria-label="Filtra por status" v-model="searchStatus">
              <option value="">Status</option>
              <option value="em-andamento">Em andamento</option>
              <option value="concluida">Concluído</option>
            </select>
          </div>
          <div class="pt-3 px-4 pb-5 bg-white rounded-3 mt-4">
            <table class="table caption-top ">
              <thead>
                <tr>
                  <th scope="col" class="text-center sortable th-01" @click="sortById">Código</th>
                  <th scope="col" class="sortable th-02" @click="sortByNome">Nome</th>
                  <th scope="col">Desafio a ser superado</th>
                  <th scope="col">Data de Início</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="entrie of searchBy" :key="entrie.id">
                  <th scope="row text-center" style="text-align: center !important;">{{ entrie.id }}</th>
                  <td>{{ entrie.desafio }}</td>
                  <td>{{ entrie.proposta }}</td>
                  <td>{{ new Date(entrie.data_inicio).toLocaleDateString('pt-BR', {timeZone: 'UTC'}) }}</td>
                  <td>

                    <div v-if="typeof entrie.id === 'number' && !entrie.deletingState" class="options-wrapper ms-5">
                      <button class="btn btn-sm btn-options" type="button" @click="toggleOptions">
                        <svg class="pe-none" xmlns="http://www.w3.org/2000/svg" width="13" height="3" viewBox="0 0 13 3">
                          <path data-name="three-dots" d="M3,9.5A1.5,1.5,0,1,1,4.5,8,1.5,1.5,0,0,1,3,9.5Zm5,0A1.5,1.5,0,1,1,9.5,8,1.5,1.5,0,0,1,8,9.5Zm5,0A1.5,1.5,0,1,1,14.5,8,1.5,1.5,0,0,1,13,9.5Z" transform="translate(-1.5 -6.5)" fill="#717171"/>
                        </svg>
                      </button>
                      <div class="options-list">
                        <router-link class="btn btn-sm w-100 pe-4" :to="{ name: 'PlanosDeAcao.Visualizacao', params: { id:  entrie.id } }">
                        <div class="row">
                          <div class="col-3">
                            <img src="../../assets/icons/eye-fill.svg">
                          </div>
                          <div class="col-9 text-left">
                            Mais detalhes
                          </div>
                        </div>
                        </router-link>
                        <router-link class="btn btn-sm w-100 pe-4" :class="{ 'd-none' : entrie.deletingState }" :to="{ name: 'PlanosDeAcao.Edicao', params: { id:  entrie.id } }">
                          <div class="row">
                            <div class="col-3"><img src="../../assets/icons/pencil-fill.svg"></div>
                            <div class="col-9 text-left">Editar</div>
                          </div>
                        </router-link>
                        <button class="btn btn-sm w-100 pe-4" v-if="!entrie.deletingState" :class="{ 'd-none' : entrie.deletingState }" type="button" @click="confirmarDelecao(entrie)">
                          <div class="row">
                            <div class="col-3">
                              <img src="../../assets/icons/trash-fill.svg">
                            </div>
                            <div class="col-9 text-left">
                              Excluir
                            </div>
                          </div>
                          </button>
                        <button v-else class="btn btn-sm w-100 pe-4" type="button">
                          Deletando <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import { PlanosDeAcaoRepository } from '../../libs/repositories';
import moment from 'moment';
const maxPerPage = 20;

export default {
  name: 'PlanosDeAcaoListagem',
  data () {
    return {
      collectionData: [],
      collectionPage: 1,
      maxPerPage: maxPerPage,
      search: '',
      searchStatus: '',
      searchPeriodo: ''
    };
  },
  async created () {
    this.collectionData = await PlanosDeAcaoRepository.getAll();
  },
  methods: {
    async confirmarDelecao (registro) {
      if (window.confirm(`Deseja realmente deletar permanentemente o registro?\nid: ${registro.id}\nnome: "${registro.nome_da_acao}"`)) {
        registro.deletingState = true;
        const wasDeleted = await PlanosDeAcaoRepository.deleteById(registro.id);
        registro.deletingState = wasDeleted;

        // this.updateCollectionData();

        window.alert(
          (wasDeleted)
            ? `O registro de id: ${registro.id}.\nnome: "${registro.nome_do_ator}"\nFoi deletado`
            : 'Houve algum problema e o registro não pode ser deletado'
        );
      }
    }
  },
  computed: {
    searchBy () {
      let collectionData = [];
      const today = moment();
      let weekSearch;

      if(this.searchPeriodo === 'semana'){
        weekSearch = today.week() - 1;
      } else if(this.searchPeriodo === 'mes'){
        weekSearch = today.week() - 4;
      } else{
        weekSearch = 0
      }


      collectionData = this.collectionData.filter(search => {// eslint-disable-line
      console.log(search);
        return search.desafio.toLowerCase().includes(this.search.toLowerCase()) && 
        (moment(search.data_inicio).week() >= weekSearch && moment(search.data_inicio).week() <= today.week()) &&
        (this.searchStatus === 'em-andamento' ? moment(search.data_conclusao) > moment() : search.data_conclusao && 
        this.searchStatus === 'concluida' ? moment(search.data_conclusao) <= moment() : search.data_conclusao)
      });
      return collectionData;
    }
  }
};

</script>

<style scoped>
thead{
  border: 0px !important;
}
tbody{
  border: 1px solid #CED4DA !important;
  border-radius: 4px;
}
tbody tr{
  border-bottom: 1px solid #dde1e6 !important;
}
th{
  border-bottom: 1px solid #dde1e6 !important;
}
.options-wrapper {
  display: inline-flex;
  flex-direction: column;
  justify-content: right;
  position: relative;
}
.options-wrapper:hover .options-list {
  display: block;
}
/* .options-wrapper.active .options-list {
  display: block;
} */
/* .options-wrapper.active .btn-options, */
.options-wrapper:hover .btn-options {
  z-index: 3;
}
.btn-options {
  z-index: 1;
}
.options-list {
  display: none;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 2;
  padding: 37px 3px 2px;
  width: fit-content;
  height: fit-content;
  width: 180px;

}
.light-blue-organizacao{
  background-color: #6DCFF6 !important;
  color: #2C3A65;
}
.blue-pessoa-fisica{
  background-color: #048090 !important;
  color: white !important;
}
.btn-dark-blue{
  background-color: #2C3A65;
  color: white;
}
.busca-lista{
  width: auto;
  min-width: 400px;
  background-image: url('../../assets/icons/search.svg');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 8px);
  background-position-y: center;
}
h1{
  font-size: 22px;
  font-weight: bolder;
}
h1+span{
  font-size: 14px;
  color: #717171;
}
.sortable {
  cursor: pointer;
}
.sortable::after {
  content: close-quote;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-image: url('../../assets/sort-icon.svg');
  background-position: center;
  background-repeat:  no-repeat;
  background-size: contain;
  vertical-align: middle;
  margin-left: .4em;
}
.text-center{
  text-align: center !important;
}
.titulo-pag{
  font-size: 14px;
  font-weight:600;
  color: #0A85CA;
}
.text-left{
  text-align: left;
}
</style>
